<template>
    <div class="page-container">
        <md-app>
            <app-toolbar></app-toolbar>
            <app-drawer></app-drawer>
            <md-app-content>
                <div class="md-layout">
                    <md-card>
                        <md-table>
                            <md-table-row>
                                <md-table-head>Facility</md-table-head>
                                <md-table-head>City</md-table-head>
                                <md-table-head>Start date</md-table-head>
                                <md-table-head>Renewal date</md-table-head>
                                <md-table-head>Status</md-table-head>
                                <md-table-head>Edit</md-table-head>
                            </md-table-row>

                            <md-table-row v-for="item in tableData" :key="item.id">
<!--                                TODO FILL OUT THIS TABLE -->
                                <md-table-cell>{{item.facility}}</md-table-cell>
                                <md-table-cell>{{item.city }}</md-table-cell>
                                <md-table-cell>{{item.startDate | formatDate}}</md-table-cell>
                                <md-table-cell>{{item.renewalDate  | formatDate}}</md-table-cell>
                                <md-table-cell>{{item.status}}</md-table-cell>
                                <md-table-cell>
                                    <span
                                            class="cancel"
                                            @click="startCancelSubscription(item.id)"
                                            v-if="item.status === 'Active'"
                                    >Stop</span>
                                </md-table-cell>
                            </md-table-row>
                        </md-table>
                    </md-card>
                     <div class="notice" v-show="subscriptions.length === 0 && !loading">
                        <span class="title">You do not have any subscriptions yet.</span>
                        <span class="intro">But as soon as something happens, you'll find them right here.</span>
                    </div>
                    <md-progress-spinner md-mode="indeterminate" class="spinner" v-show="loading && subscriptions.length === 0"></md-progress-spinner>
                </div>      
            </md-app-content>
        </md-app>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex'
    import { format, getDate, getDaysInMonth, setDate, addMonths } from 'date-fns'
    export default {
        name: "TransactionList",
        data() {
            return {
                loading: true
            }
        },
        computed: {
            ...mapGetters([
                'subscriptions',
                'facilities'
            ]),
            tableData(){
                const tableData = []
                for(const subscription of this.subscriptions){
                    const facility = this.facilities.find(facility => facility.attributes.facilityId === subscription.facilityId);
                    // console.log('facility', JSON.parse(JSON.stringify(facility)))
                    // console.log('subscription', JSON.parse(JSON.stringify(subscription)))

                    let status = subscription.active ? 'Active' : 'Inactive';
                    if(subscription.active && subscription.cancel){
                        status = "Cancelling on " + this.subscriptionEndDate(subscription)
                    }

                    tableData.push({
                        id: subscription.id,
                        facility: facility.attributes.name,
                        city: facility.attributes.city + ", " + facility.attributes.state,
                        startDate: subscription.startDate,
                        renewalDate: addMonths(subscription.startDate, 1),
                        status,
                    })
                }
                return tableData
            }
        },
        filters: {
            formatDate(date){
                return format(date, 'MM/DD/YYYY')
            },
            formatPrice(value) {
                const formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 2,
                })
                return formatter.format(value)
            },
        },
        async created(){
            await this.fetchSubscriptions();
            this.loading = false;
        },
        methods: {
            ...mapActions([
                'fetchSubscriptions'
            ]),
            startCancelSubscription(subscriptionId){
                this.$router.push({
                    name: 'settings.subscriptionCancel',
                    params: {subscriptionId}
                })
            },
            subscriptionEndDate(subscription) {
                const daysThisMonth = getDaysInMonth(new Date());
                const daysNextMonth = getDaysInMonth(addMonths(new Date(), 1));
                const subscriptionDateOfMonth = getDate(subscription.startDate);
                const todaysDateOfMonth = getDate(new Date());

                if(todaysDateOfMonth < subscriptionDateOfMonth){
                    if(subscriptionDateOfMonth <= daysThisMonth){
                        return format(setDate(new Date(), subscriptionDateOfMonth), 'MM/DD/YYYY')
                    } else {
                        return format(setDate(new Date(), daysThisMonth), 'MM/DD/YYYY')
                    }
                }else {
                    if(subscriptionDateOfMonth <= daysNextMonth){
                        return format(setDate(addMonths(new Date(), 1), subscriptionDateOfMonth), 'MM/DD/YYYY')
                    }
                    else {
                        return format(setDate(addMonths(new Date(), 1), daysNextMonth), 'MM/DD/YYYY')
                    }
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    span {
      width: 100%;
      height: 100%;
      padding: 8px;
      display: block;
      background: md-get-palette-color(blue, 200);
    }
    .cancel {
        cursor: pointer;
    }
    .notice{
        margin-top: 80px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        text-align: center;

        .title{
            font-size: 22px;
            font-weight: 500;
        }
        .intro{
            font-size: 16px;
            font-weight: 300;
        }
        span{
            height: unset;
        }
    }
    .url{
        cursor: pointer;
    }
    .text-center{
        text-align: center;
    }
    .md-title{
        margin-bottom: 20px;
    }
    .md-app-content .md-card{
        margin-left: 0px;
        width: 100%;
        margin-right: 0px;
    }
    .md-app-content {
        margin-left: 0px;
        margin-right: 0px;
        padding-left: 0px;
        padding-top: 0px;
        padding: 0px;
    }

      .spinner{
        margin-left: auto;
        margin-right: auto;
        margin-top: 18%;
        position: absolute;
        left:50%;
        margin-left: -40px;
    }
</style>